<template>
    <div class="content pt-32 pb-12 xl:pb-20">
        <div class="container mx-auto flex justify-center">
            <div class="px-6 max-w-2xl text-gray-700">
                <h1 class="text-3xl xl:text-4xl font font-heading mb-5 text-gray-700 text-center font-bold">About Us<span class="font-mono text-red-600">.</span></h1>

                <p class="font-condensed leading-relaxed mb-4 text-lg">Inquisition is a syndicate of freelance creatives based throughout the UK and Europe. We specialise in the delivery of innovative and highly effective design, web and branding solutions.</p>

                <p class="font-body mb-4 font-light">Having existed since 1998, Inquisition now serves a wide range of clients in the UK, Germany, the US, Australasia and Asia. They include companies engaged in the food and beverage industry, the music industry and the professional, finance and IT sectors.</p>

                <p class="font-body mb-4 font-light">Our ethos is to create a unique solution for each of our clients’ businesses so if you decide to work with Inquisition you will find that we are highly motivated by the results of our work and the happiness of our clients.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'content-single',
        components: {

        }
    }
</script>